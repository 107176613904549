<template>
  <div class="main-container">
    <h5 class="pageTitle" v-html="pageTitle"></h5>
    <!-- <button class="btn-register" @click="goToRegister">등록</button> -->
    <mdb-container class="search-container">
      <mdb-row class="search-row">
        <mdb-col class="search-col">
          <select v-model="searchCriteria" class="search-select">
            <option value="model">모델(타입)</option>
            <option value="name">인증명</option>
            <option value="expire_date">인증 만료일</option>
            <option value="sms_YN">SMS 예약 여부</option>
          </select>
          <input
            v-model="searchQuery"
            type="text"
            placeholder="검색할 내용을 입력하세요"
            class="search-input"
            @input="filterResults"
          />
          <button class="btn-search" @click="filterResults">검색</button>
          <button class="btn-register" @click="goToRegister">등록</button>
        </mdb-col>
      </mdb-row>
    </mdb-container>
    <!-- 테이블 -->
    <table>
      <thead>
        <tr>
          <th>NO.</th>
          <th>모델(타입)</th>
          <th>안정성 인증 검사명</th>
          <th>안정성 인증 검사 만료일</th>
          <th>SMS 예약 여부</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="paginatedData.length === 0">
          <td colspan="5">데이터가 없습니다.</td>
        </tr>
        <tr v-for="(item, index) in paginatedData" :key="index" :class="{ 'alt-row': index % 2 === 1 }" @click="goToUpdateDelete(item.uuid)">
          <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
          <td>{{ item.model }}</td>
          <td>{{ item.name }}</td>
          <td>{{ formatDate(item.expire_date) }}</td>
          <td>{{ item.sms_YN ? '예' : '아니오' }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 페이지네이션 -->
    <div class="pagination">
      <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">Prev</button>
      <span>Page {{ currentPage }} of {{ pageCount }}</span>
      <button class="pagination-button" @click="nextPage" :disabled="currentPage === pageCount">Next</button>
    </div>
  </div>
</template>

<script>
import { mdbCol, mdbContainer, mdbRow } from "mdbvue";
import axios from "@/axios";

export default {
  components: { mdbContainer, mdbRow, mdbCol },
  data() {
    return {
      pageTitle:'드론 안정성 인증검사 조회',
      results: [], // 원본 데이터
      filteredResults: [], // 필터링된 데이터
      searchCriteria: "model",
      searchQuery: "",
      currentPage: 1,
      perPage: 10, // 페이지당 데이터 수
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.filteredResults.slice(start, start + this.perPage);
    },
    pageCount() {
      return Math.ceil(this.filteredResults.length / this.perPage);
    },

   
  },
  methods: {
    async fetchResults() {
      try {
        const tokenData = JSON.parse(sessionStorage.getItem('token'));
        const token = tokenData ? tokenData.access_token : '';

        const response = await axios.get('/safety-cert/read', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('서버 응답 데이터:', response.data);
        // 서버 데이터 매핑
        this.results = response.data.map((item, index) => ({
          no: index + 1,
          uuid: item.uuid,
          model: item.model,
          model_ID:item.model_ID,
          name: item.name,
          expire_date: item.expire_date,
          sms_YN: item.sms_YN,
        }));
        this.filteredResults = this.results; // 초기 필터링 데이터 설정
      } catch (error) {
        console.error('데이터 조회 실패:', error);
      }
    },

    filterResults() {
      const query = this.searchQuery.trim().toLowerCase();
      if (!query) {
        this.filteredResults = [...this.results];
        return;
      }
      this.filteredResults = this.results.filter((item) => {
        let value = "";
        if (this.searchCriteria === "sms_YN") {
          value = item.sms_YN ? "예" : "아니오";
        } else {
          value = item[this.searchCriteria]?.toString().toLowerCase() || "";
        }
        return value.includes(query);
      });
      this.currentPage = 1;
    },

    formatDate(dateString) {
      if (!dateString) return "N/A";
      const date = new Date(dateString);
      if (isNaN(date)) return "Invalid Date";
      const year = date.getFullYear().toString().slice(2);
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.pageCount) this.currentPage++;
    },
    goToRegister() {
      this.$router.push({ 
            name: "safetyCert-create" , 
            query: {
              certs: JSON.stringify(this.results), // 데이터를 JSON으로 직렬화하여 전달
            },
      }); // 등록 화면으로 이동
    },
    goToUpdateDelete(uuid) {
        if (!uuid) {
          console.error("UUID가 전달되지 않았습니다.");
          return;
        }
        this.$router.push({ name: "safetyCert-updateDelete", params: { id: uuid } });
    },
  },
  mounted() {
    this.fetchResults();
  },
};
</script>

<style scoped>
.main-container {
  padding: 20px;
}

.pageTitle {
  font-weight: bold;
 
}

.search-container {
    padding: 25px;
 
  }
  
  .search-row {
    display: flex;
    align-items: center;
    justify-content: space-between; /* 오른쪽 정렬 */
  }
  
  .search-col {
    display: flex;
    align-items: center;
    gap: 10px; /* 각 요소 사이에 간격 추가 */
  }
  
  .search-select {
    height: 38px;
    border-radius: 3px;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }
  
  .search-input {
    height: 38px;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }
  
  .btn-search {
    background-color: #e6f2ff;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    line-height: 1rem;
    color: #3e3e3e;
    margin-right: 20px; /* 검색 버튼과 등록 버튼 사이의 여백 */
    /*cursor: pointer;
     transition: background-color 0.3s ease; */
  }
  .btn-register {
    background-color: #ffc107;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    line-height: 1rem;
    color: #3e3e3e;
    margin-left: auto; /* 등록 버튼을 오른쪽 끝으로 정렬 */
    align-self: flex-end; /* 등록 버튼을 아래쪽으로 정렬 */
    }

table {
  width: 80%;
  border-collapse: collapse;
  text-align: center;
  margin: auto;
}

th, td {
    border: 1px solid #cbc8c8;
    padding: 8px;
    text-align: center;
  }
  
  th {
    background-color: #e3e4eb;
    height: 55px;  
  }
  
  tr {
    height: 50px;
    cursor: pointer;
  }

.alt-row {
  background-color: #f9f9f9;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
</style>
